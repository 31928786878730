import React, { useEffect } from 'react';
import { Container, Typography } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import "./Address.css";
import { ShoppingCart } from "@mui/icons-material";
import AppleIcon from '@mui/icons-material/Apple';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useSection } from '../../context/SectionContext';
import { useDatosUser} from "../../context/userContext";
import { useNavigate } from 'react-router-dom';
import useRedirectIframe from "../../hooks/useRedirectIframe";

function Address() {
    const navigate = useNavigate();
    const { changeSection } = useSection();
    const { nombre, completed } = useDatosUser();

    useRedirectIframe('https://wuuju.co/pages/casillero-virtual/');

    useEffect(() => {
        if (completed === false || completed === null) {
            navigate('/crear-casillero-2');
        }
    }, [completed, navigate]);

    return (
        <Container maxWidth="xl" className="center-container">

            <div className="center-content">
                <h1 className='Title-Wuuju'>¡Wuuuuuujuuu!</h1>
                <h3 className='Subtitle-address'>{nombre}, estas son tus <br /> direcciones en Miami</h3>
                <div className='button-container'>
                    {/*<button className='Button-express' onClick={() => changeSection('DetallesDirecciones', 'express' )} >
                        {<ShoppingCart />} Compras menores a 200 USD Express  <AccessTimeIcon style={{ marginLeft: '1vw' }}/>       
                          
                    </button>*/}
                <button className='Button-minor' onClick={() => changeSection('DetallesDirecciones', 'minor')} >{<ShoppingCart />} Compras menores a 200 USD</button>
                <button className='Button-major' onClick={() => changeSection('DetallesDirecciones', 'major')} >{<ShoppingCart />} Compras mayores a 200 USD</button>
                <button className='Button-Apple' onClick={() => changeSection('DetallesDirecciones', 'apple')} >{<AppleIcon />} Compras en Apple</button>
                </div>

                <div className='typography-content'>
                <Typography
                        sx={{
                            color: 'gray',
                            fontFamily: 'Viga, sans-serif',
                            fontSize:  {xs: "3vw", sm: "1vw"},
                            position: 'relative',
                            zIndex: 1,
                        }}
                    >
                        ─── ¿Tienes dudas?,
                        </Typography>
                            <WhatsAppIcon className="whatsapp-icon"
                                sx={{
                                    color: "#5452ff",
                                    fontSize:  {xs: "4vw", sm: "1.5vw"},
                                    marginBottom: {xs:"2vw", sm: "0.5vw"}
                                   
                                }} />
                        <Typography
                        sx={{
                            color: 'gray',
                            fontFamily: 'Viga, sans-serif',
                            fontSize:  {xs: "3vw", sm: "1vw"},
                            position: 'relative',
                            zIndex: 1,
                        }}
                        >
                        <a
                            href="https://wa.link/5w0okn"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="whatsapp-link"
                        >
                            Contáctanos
                        </a>
                        ───
                    </Typography>
                </div>

            </div>
        </Container>
    );
}

export default Address;